
.announcements {
    .section-layout {
     // min-height: calc(100vh - 115px ;
      height: calc(100vh - 120px);
      display: flex;
      gap: 1rem;
    }
    section {
      &:first-child {
        box-shadow: 0px 2px 4px 0px #61616133;
        width: 30rem;
        min-width: 25rem;
        overflow-y: auto;
      
      }
      &:last-child {
        flex-grow: 1;
      }
    }
  

    .media-preview-thumb {
      min-width: 7rem;
      width: 7rem;
      height: 7rem;
      max-height: 7rem;
    }
  
    .avatar {
      height: fit-content;
      padding: 0.8rem;
    }

    .preview-file {
      max-height: calc(100vh - 392px);
    }

    
  }
  