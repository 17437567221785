.companies-list {
  width: 100%;
  max-width: 300px;

  .list {
    height: auto;
    max-height: 13rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .company-record {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 2.5rem;
      border: 1px solid $theme-grey-1;
      padding: 0 1.25rem;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: $theme-green-1;
        border-color: $theme-green-1;

        img {
          filter: brightness(5);
        }
        label {
          color: $theme-white-1;
        }
      }
    }
  }
}

.company-header {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: $theme-white-2;
  border-radius: 4px;
  cursor: pointer;
  label {
    color: $theme-grey-1;
  }
  &.active {
    background-color: $theme-green-1;

    label {
      color: $theme-white-1;
    }
  }
}

.company-settings-style {
  .example-text {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: $theme-green-1;
  }
  .responsive-box {
    display: flex;
    gap: 1rem;
    > div {
      width: 50%;
    }
  }
}

@media screen and (max-width: #{$tablet-max-width}px) {
  .company-settings-style {
    .responsive-box {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  }
}

.branding {
  line-height: 25px;
  font-family: 'PlusJakartaSans Medium' !important;

  .brand-logo {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem;

    > div {
      width: 100%;
      max-width: 400px;
      min-width: 300px;
    }

    .preview-box {
      box-shadow: 0px 1px 4px 1px $theme-black-4;
      border-radius: 3px;
      height: 130px;
      padding: 1.5rem 2rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      > img.preview-image {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .branding-buttons {
    .custom-form-button {
      width: 200px;
      height: 43px;
      border-radius: 4px;
      font-family: 'PlusJakartaSans Regular';

      img {
        width: 26.6px;
        height: 26.6px;
      }
    }
  }

  .color-scheme {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem;

    > div {
      max-width: 450px;
      min-width: 320px;
    }

    .color-picker {
      border: 1px solid $theme-grey-11;
      border-radius: 5px;
      padding: 1rem;
      display: flex;
      gap: 0.75rem;
      flex-wrap: wrap;

      .color-box {
        width: 1.5rem;
        height: 1.6rem;
        border-radius: 0.3rem;

        &:last-child {
          border: 1.5px solid $theme-grey-2;
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;
        }
      }
    }
  }

  .app-screen {
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;

    > div {
      width: 100%;
      max-width: 400px;
      min-width: 300px;
    }

    .tagline-text input,
    .tagline-text {
      font-family: 'PlusJakartaSans Regular';
      font-weight: 600;
      text-align: center;
      color: $theme-black-1;
      font-size: 1rem;
    }

    .description-text input,
    .description-text {
      font-family: 'PlusJakartaSans Medium';
      font-size: 0.9rem;
      letter-spacing: 0.2px;
      text-align: center;
      height: 99px;
    }

    .privacy-policy {
      font-family: 'PlusJakartaSans Medium';
      line-height: 12px;
      letter-spacing: 0.22px;
    }

    .preview-box {
      margin-top: -50px;
      margin-bottom: -30px;

      background-image: url('../../images/mobile-screen.svg');
      background-repeat: no-repeat;
      background-size: cover;

      .mobile-screen {
        top: 4.5rem;
        right: 1.7rem;
        border-radius: 22px;
        background-color: $theme-white-1;

        .logo {
          height: 8rem;
          width: 8rem;
          background-image: url('../../images/logo.png');
          background-repeat: no-repeat;
          background-origin: content-box;
          background-position: center;
          background-size: cover;

          img {
            height: 8rem;
            width: 8rem;
            background: $theme-grey-12;
          }
        }
      }
    }
  }

  @media screen and (max-width: #{$tablet-max-width}px) {
    .brand-logo,
    .color-scheme,
    .app-screen {
      flex-direction: column;
    }
  }
}
