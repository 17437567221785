.table-pagination {
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Inter Medium';
  .displayRecords {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .paginationList,
  .displayed-entries {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .item {
      padding: 0.3rem 0.8rem;
      border: 1px solid $theme-grey-10;
      border-radius: 10px;
      cursor: pointer;
      &.disabled {
        color: $theme-grey-10;
        cursor: not-allowed;
      }
      &.active {
        background-color: $theme-green-1;
        color: $theme-white-1;
      }
      &:first-child,
      &:last-child {
        border: 0;
      }
    }
    .pagination-dots + .pagination-dots {
      display: none;
    }
  }
  .displayed-entries {
    .item {
      border: 1px solid $theme-grey-10 !important;
    }
  }
}
