.basic-settings {
  display: flex;
  flex-direction: column;
  .basic-title {
    color: $theme-grey-1;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $theme-grey-10;
    margin-bottom: 0;
    background-color: $theme-white-1;
  }
  .all-sections {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .sections {
      display: flex;
      gap: 0.5rem;
      > div {
        background-color: $theme-white-1;
        border-radius: 0.5rem;
        box-shadow: 1px 2px 6px 0px $theme-grey-8;
        &:first-child {
          width: 60%;
          padding: 1.5rem;
        }
        &:last-child {
          width: 40%;
          padding: 1.5rem;
        }
      }
      .detail {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
      .setting {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}
