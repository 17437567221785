.sidebar {
  background-color: #ffffff;
  width: 250px;
  height: calc(100vh - 60px);
  position: fixed;
  top: 70px;
  left: 0;
  border-right: 1.5px solid $theme-grey-2;
  border-left: 1.5px solid $theme-grey-2;
  overflow-x: hidden;
  overflow-y: auto;

  section {
    width: 100%;
    padding: 10px 10px 10px 20px;
    &:not(:last-child) {
      border-bottom: 1.5px solid $theme-grey-2;
    }
    p {
      font-size: 1rem;
      margin: 0;
      padding-left: 15px;
      font-family: 'PlusJakartaSans Semi Bold';
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        height: 40px;
        display: flex;
        cursor: pointer;
        gap: 0.5rem;
        align-items: center;
        padding: 5px 15px;
        font-family: 'PlusJakartaSans Regular';
        user-select: none;
        margin: 1px 0;
        &.active,
        &:hover {
          background-color: $theme-green-1;
          border-radius: 8px;
          span {
            color: #ffffff;
          }
          img {
            filter: brightness(5);
          }
        }
      }
    }
  }
}

@media screen and (max-width: #{$tablet-max-width}px) {
  .sidebar {
    width: 60px;
    section {
      padding: 0;
      p {
        display: none;
      }
      ul li {
        height: 45px;
        justify-content: center;
        padding: 0;
        &.active,
        &:hover {
          border-radius: unset;
        }
        span {
          display: none;
        }
      }
    }
  }
}

html[dir='rtl'] .sidebar {
  left: auto;
}
