.form-design {
  .sub-header {
    width: 100%;
    height: 65px;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid $theme-grey-1;
    background-color: $theme-white-1;
  }
  .section-layout {
    height: auto;
    min-height: calc(100vh - 270px);
    display: flex;
    gap: 1rem;
    direction: var(--direction);

    section {
      background-color: $theme-white-1;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
      &:first-child,
      &:last-child {
        width: 22rem;
        min-width: 15rem;
      }
      &:not(:first-child):not(:last-child) {
        flex-grow: 1;
      }
      &:last-child {
        height: fit-content;
        min-height: inherit;
      }

      @media screen and (max-width: #{$tablet-max-width}px) {
        &:first-child {
          width: 18rem;
          min-width: 15rem;
        }
        &:last-child {
          width: 22rem;
          min-width: 15rem;
        }
      }

      .section-header-area {
        height: 5rem;
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid $theme-grey-1;
        img {
          width: auto;
          height: 1.5rem;
        }
      }
    }

    .fields-playground {
      font-family: 'PlusJakartaSans Regular';
      // height: calc(100vh - 340px);
      // height: calc(100% - 70px);
      overflow-y: auto;

      .fields-page {
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .page-header {
          font-family: 'PlusJakartaSans Semi Bold';
          background-color: $theme-grey-5;
          padding: 0 0.75rem;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: space-between;
        }
      }
      .fields-section {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        user-select: none;
        .fields-section-header {
          background-color: $theme-white-2;
          padding: 0.25rem 0.75rem;
          margin-bottom: 0.25rem;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          &:hover {
            background-color: $theme-green-2;
            img.field-type-icon {
              filter: unset;
            }
          }
        }
        .fields-section-header-selected {
          background-color: $theme-green-2;
        }
        .fields-section-description {
          padding: 0.75rem 1rem;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
        }
      }
      .fields-section-selected {
        .fields-section-header {
          background-color: $theme-green-2;
        }
      }
      .field-component {
        user-select: none;
        .field-component-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          padding: 0 0.75rem;
          gap: 1rem;
          background-color: $theme-white-2;
          &:hover {
            background-color: $theme-green-2;
            img.field-type-icon {
              filter: unset;
            }
          }
        }
        .field-component-body {
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
          padding: 0.75rem 1rem;
          display: none;
        }
      }
      .field-component-selected {
        .field-component-header {
          background-color: $theme-green-2;
        }
        .field-component-body {
          display: block;
        }
      }

      .field-detail-view {
        padding: 0.25rem;
        .choice-list-option-box {
          width: 100%;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          > label {
            color: $theme-white-1;
          }
        }
      }
    }
  }

  .form-field-selection-item {
    background-color: $theme-white-2;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 0.25rem 0.5rem;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    user-select: none;
    img {
      width: auto;
      height: 1rem;
    }
  }

  .rating-box {
    border: 1px solid $theme-grey-2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.form-report-layout-setting {
  padding: 1rem 1.5rem;
  display: flex;
  gap: 2rem;

  > .preview-options-panel {
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-top: 5rem;
    border-left: 1px solid $theme-grey-2;

    > div {
      &:not(:first-child) {
        width: 16rem;
        height: 8rem;
        border-radius: 5px;
        background-color: $theme-white-2;
        border: 1px solid $theme-grey-1;
        img {
          filter: grayscale(1);
        }
        label {
          color: $theme-grey-1;
        }
        &:hover {
          background-color: $theme-green-2;
          border: 1px solid $theme-green-1;
          img {
            filter: unset;
          }
          label {
            color: $theme-green-1;
          }
        }
        &.active {
          background-color: $theme-green-1;
          border: 1px solid $theme-green-1;
          img {
            filter: brightness(5);
          }
          label {
            color: $theme-white-1;
          }
        }
      }
    }
  }

  .thumbnail-selection-field {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    width: 100%;

    > div {
      .option {
        width: 10rem;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid $theme-grey-1;
        border-radius: 5px;
        position: relative;
        padding: 0 0.75rem;

        &.active {
          background-color: $theme-green-2;
          border: 1px solid $theme-green-1;
        }

        .check {
          position: absolute;
          right: 0;
          top: 0.5rem;
        }
      }
    }
  }
}

.drop-zone {
  width: 100%;
  height: auto;
  padding: 0.25rem;

  .drop-area {
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    color: transparent;

    &.shrunk {
      height: 1rem;
    }
  }
  .drop-area-hovered {
    border: 2px dashed $theme-green-1;
    border-radius: 5px;
    height: 20rem;
    background-color: #38cb8915;
    color: $theme-green-1;

    &.shrunk {
      height: 5rem;
    }
  }
}

.form-element-swapping {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  * {
    cursor: move;
    &:last-child {
      cursor: pointer;
    }
  }
}
.page-elements {
  > :nth-child(2) {
    .form-element-swapping {
      :first-child {
        display: none;
      }
    }
  }
  > :last-child {
    .form-element-swapping {
      :nth-last-child(2) {
        &:not(.page) {
          display: none;
        }
      }
    }
  }
}

.supervisor-elements {
  > :first-child {
    .form-element-swapping {
      :first-child {
        display: none;
      }
    }
  }
  > :last-child {
    .form-element-swapping {
      :nth-last-child(2) {
        display: none;
      }
    }
  }
}

.choice-list-view {
  width: 100%;
  .list-view {
    .list-header {
      color: #000;
    }
    .list-header,
    .list-item {
      display: flex;
      gap: 0.75rem;
      border-bottom: 1px solid $theme-grey-1;
      padding: 0.25rem 0;
      user-select: none;

      &:not(:first-child):hover {
        background-color: $theme-green-2;
        .icon-col {
          img.index-icon {
            filter: unset;
          }
        }
      }

      > div {
        display: flex;
        align-items: center;
        padding: 0.25rem;
        > img {
          width: auto;
          height: 1.5rem;
        }
      }
      .icon-col {
        width: 2rem;
        justify-content: center;
        img.index-icon {
          filter: grayscale(1);
        }
      }
      .text-col {
        width: calc((100% - 7rem) / 2);
      }
      .color-col {
        width: 2.5rem;
        justify-content: center;
      }

      .color-box {
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        padding: 0.25rem 0;
      }
    }
    .list-item:last-child {
      border-bottom: 0;
    }
  }

  .action-label {
    img {
      width: auto;
      height: 0.75rem;
    }
  }
}

@import './settings.scss';
@import './setup.scss';
