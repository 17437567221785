.nested-menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  color: $theme-green-1;
}

.nested-menu-items {
  position: relative;
  font-size: 1rem;
}

.nested-menu-items-hover {
  background-color: $theme-green-1;
  color: $theme-white-1;
}

.arrow::after {
  content: '';
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.permission-dropdown {
  position: relative;
  width: max-content;
}
.nested-menu-dropdown {
  position: absolute;
  left: auto;
  box-shadow:
    0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.nested-menu-show {
  display: block;
}

.nested-dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}
