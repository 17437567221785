.submissions {
  > .table-responsive {
    width: 100%;
    tr {
      border-bottom: 1px solid $theme-grey-10;
      td {
        height: 4.5rem !important;
      }
    }
  }
  .divider {
    text-orientation: mixed;
    writing-mode: vertical-rl;
    display: flex;
    rotate: 180deg;
    justify-content: center;
    background-color: $theme-white-2;
    padding: 0.6rem;
    border-left: 1px solid $theme-grey-1;
    align-items: center;
    line-height: 0;
  }
}

.submission-filter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;

  .custom-input-box {
    width: min(100%, 200px);
  }
  .query-filter {
    .query-row {
      > div {
        &:not(:last-child) {
          width: 200px;
        }
      }
    }
    .icon-div {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  .filter-modal {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 200px;
    background-color: $theme-white-1;
    border: 1px solid $theme-grey-1;
    border-radius: 5px;
    z-index: 99;

    .version-list {
      max-height: 145px;
      overflow-y: auto;
    }
  }
}

.attachment-overview {
  width: 100%;
  .content {
    height: auto;
    max-height: 70vh;
    overflow: auto;
    margin: 1.5rem 0;
    padding: 0 20%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .label-initial {
      background-color: $theme-green-1;
      width: 5px;
      height: 15px;
    }
  }
}
