.attendance-stat-card {
  background: $theme-white-1;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  gap: 1.25rem;
  padding: 1rem;
  border: 1px solid $theme-grey-8;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.grey-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
  width: 45px;
  height: 45px;
  border-radius: 25px;

  img {
    width: 32px;
    height: 32px;
  }
}

.map-container {
  width: 100%;
  height: calc(100vh - 450px);
}

.location-tracking-option {
  width: 100%;
  border: 1px solid $theme-grey-11;
  border-radius: 5px;
  color: $theme-grey-11;
  cursor: pointer;
  text-align: center;

  &.active {
    border: 1px solid $theme-green-1;
    color: $theme-grey-2;
  }
}
