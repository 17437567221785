.activity {
  .border-bottom-green {
    border-bottom: 2px solid $theme-green-1;
    border-radius: 0.6rem;
  }
  .activity-tab-body {
    .activity-item {
      border-left: 1px solid $theme-black-5;
      margin-left: 3rem;
      .initials {
        position: relative;
        .user-list {
          position: absolute;
          left: calc(-3rem + 2px);
          z-index: 1;
        }
      }
    }
    > div {
      &:not(:first-child) {
        padding-top: 1rem;
      }
      .date-title {
        border-bottom: 1px solid $theme-black-5;
        margin-bottom: 0;
        padding: 1rem 2rem;
      }
      :nth-child(2) {
        border: 0;
      }
    }
  }
}
