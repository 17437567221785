$mobile-max-width: 768;
$tablet-max-width: 1024;

$theme-green-1: #38cb89;
$theme-green-2: #f1f9f5;
$theme-green-3: #38cb8940;
$theme-green-4: #38cb8926;
$theme-grey-1: #636874;
$theme-grey-2: #83898c;
$theme-grey-3: #dfdfe6;
$theme-grey-4: #e2e6f9;
$theme-grey-5: #eff5f6;
$theme-grey-6: #e2e6f95c;
$theme-grey-7: #6a6a65;
$theme-grey-8: #abafb1;
$theme-grey-9: #131313b2;
$theme-grey-10: #cccccc;
$theme-grey-11: #636874b2;
$theme-grey-12: #d9d9d9;
$theme-grey-13: #63687480;
$theme-black-1: #2a2b28;
$theme-black-2: #00000035;
$theme-black-3: #484c56;
$theme-black-4: #00000040;
$theme-black-5: #00000033;
$theme-red-1: #ec3434;
$theme-red-2: #f57e77;
$theme-red-3: #ff5630;
$theme-red-4: #ec343410;
$theme-white-1: #ffffff;
$theme-white-2: #f8f8f8;
$theme-white-3: #f1f3f5;
$theme-white-4: #f1f3f545;
$theme-blue-1: #377dff;
$theme-blue-2: #377dff36;
$theme-blue-3: #56ccf2;
$theme-orange-1: #ffa600;
$theme-orange-2: #ffa60036;
