.add-user-form-section {
  width: 100%;
  padding: 2rem;

  .add-user-form {
    width: calc(100% - 4rem);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-x: auto;

    .add-user-form-fields {
      display: flex;
      .add-user-form-field {
        width: 375px;
        .field-header {
          background-color: $theme-white-2;
          width: 100%;
          height: 3rem;
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0 1rem;
        }
        .field-body {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 1rem;
        }
      }
      .header-space-filler {
        background-color: $theme-white-2;
        height: 3rem;
        flex-grow: 1;
      }
    }
  }
  .add-user-action {
    .fields-input-action {
      width: 4rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      }
    }
  }
}

.add-user-qr-section {
  .header-bar {
    background-color: $theme-white-2;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    label {
      font-family: 'PlusJakartaSans Medium';
      font-weight: 600;
      font-size: 1.5rem;
      color: $theme-black-1;
    }
    img {
      width: auto;
      height: 1.5rem;
    }
  }

  .field-check-list {
    height: auto;
    max-height: 35vh;
    overflow-y: auto;
  }

  .qr-scan-section {
    width: 30%;
    max-width: 450px;

    .section {
      padding: 3rem;
    }
  }
}

@media screen and (max-width: #{$tablet-max-width}px) {
  .add-user-form-section .add-user-form .add-user-form-fields .add-user-form-field {
    width: 275px;
  }

  .add-user-qr-section {
    .field-check-list {
      max-height: 24vh;
    }
    .qr-scan-section {
      width: 35%;
      .section {
        padding: 2rem 3rem;
      }
    }
  }
}

.add-user-button-layout {
  position: fixed;
  bottom: 6rem;
  right: 4rem;
}

.tab-layout-body:has(.user-details) {
  border: 0;
}

.tab-layout-body:has(.user-activity),
.activity-log {
  border: 0.5px solid $theme-grey-13;
  border-top: 0;
  box-shadow: 0px 2px 3px 0px $theme-black-5;
}

.user-details {
  .tab-heading,
  .tab-body {
    box-shadow:
      0px 1px 2px #61616133,
      0px 2px 4px #61616133;
  }

  .tab-body {
    border: 0.5px solid $theme-grey-13;
    border-top: 0;
    border-radius: 0px 0px 4px 4px;
  }
}
.tags-list {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $theme-black-5;
    z-index: 99;
  }
  .side-popup {
    position: fixed;
    top: 70px;
    right: 0;
    height: calc(100vh - 70px);
    min-width: 20rem;
    max-width: 35rem;
    background-color: $theme-white-1;
    overflow-y: auto;
    z-index: 100;
    border-radius: 4px;

    .list-item:hover {
      background-color: $theme-black-5;
    }

    .tag-input {
      border: none;
      background-color: transparent;
      width: 10rem;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.activity-log {
  .single-item:hover {
    background-color: aliceblue;
  }
  .single-item.selected {
    background-color: $theme-green-1;
    span {
      color: $theme-white-1;
    }
  }
}
.cursor-not-allowed {
  cursor: not-allowed;
}
