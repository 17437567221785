.main-layout {
  position: fixed;
  width: calc(100% - 250px);
  height: calc(100% - 70px);
  bottom: 0;
  right: 0;

  .main-layout-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 3rem 1.5rem;
    overflow-y: auto;
  }
}

@media screen and (max-width: #{$tablet-max-width}px) {
  .main-layout {
    width: calc(100% - 60px);
    .main-layout-container {
      padding: 2.5rem 1.5rem;
    }
  }
}

.non-auth-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  .left {
    background-color: $theme-green-2;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      position: absolute;
    }
    img.promo {
      width: auto;
      height: 580px;
      overflow: hidden;
    }
    @media screen and (max-width: 1000px) {
      img.promo {
        height: 420px;
      }
    }
    img.logo {
      width: 60px;
      height: auto;
      top: 30px;
      left: 30px;
    }
    .bar {
      width: 100%;
      height: 225px;
      background-color: $theme-green-1;
    }
    @media screen and (max-width: 1000px) {
      .bar {
        height: 160px;
      }
    }
    .bar + .bar {
      margin-top: 10px;
    }
  }
  .right {
    background-color: #ffffff;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .back-navigation {
      position: absolute;
      z-index: 10;
      top: 2rem;
      left: 2rem;
      display: flex;
      gap: 0.5rem;
      cursor: pointer;
      span {
        color: $theme-black-1;
      }
    }
  }
}

.auth-form-container {
  width: 100%;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    color: $theme-black-1;
  }

  p.sub-heading {
    width: min(85%, 320px);
    text-align: center;
  }

  .custom-form-button {
    width: min(100%, 375px);
  }

  .listing-logo {
    width: auto;
    height: 10rem;
  }
}
html[dir='rtl'] {
  .main-layout {
    left: 0;
    right: unset;
  }
}

.tab-layout-header {
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  .tab-item {
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    &.active {
      background-color: $theme-white-1;
      border-bottom: 5px solid $theme-green-1;
      border-radius: 5px 5px 0 0;
      box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.25);
    }
    &.disabled {
      cursor: not-allowed;
    }

    &.fixed {
      max-width: 400px;
    }
  }
  &.iconTab {
    padding: 1.5rem;
    gap: 1.5rem;
    width: fit-content;
    .tab-item {
      gap: 0.5rem;
      text-transform: capitalize;
      font-family: 'Inter Medium';
      color: $theme-grey-1;
      padding-bottom: 0.25rem;
      border-bottom: 2px solid transparent;
      &.active {
        box-shadow: none;
        color: $theme-green-1;
        border-bottom: 2px solid $theme-green-1;
      }
    }
  }
}

.tab-layout-body {
  border-radius: 4px;
  border-left: 1px solid $theme-grey-1;
  border-right: 1px solid $theme-grey-1;
  border-bottom: 1px solid $theme-grey-1;
  background-color: $theme-white-1;
  width: 100%;
  height: auto;
  min-height: calc(100% - 50px);
  &.iconTab {
    min-height: 50px;
    border-left: 1px solid $theme-grey-4;
    border-right: 1px solid $theme-grey-4;
    border-bottom: 1px solid $theme-grey-4;
    border-radius: 0;
  }
  &.nominheight {
    min-height: unset;
  }
  &:has(.form-setup) {
    border: 0;
    background: transparent;
  }
}

.tab-layout-body-transparent {
  border: 0;
  background-color: transparent;
}

.action-row {
  cursor: pointer;
}

.back-arrow {
  transform: rotate(0deg);
}
